<!-- 首页-学校简介 -->
<template>
    <hm-card
        v-loading="loading"
        :height="276"
        class="home-introduce"
    >
        <hm-title title="学校简介"></hm-title>
        <hm-more @click="createIntroduce"></hm-more>
        <div
            v-if="schoolIntroduce"
            class="introduce-wrap"
            @click="createIntroduce"
        >
            <div class="introduce-content">
                <span>{{ schoolIntroduce }}</span>
            </div>
        </div>
        <div
            v-else-if="!loading"
            class="introduce-empty"
        >
            <p>通过简明扼要的文字</p>
            <p>全面的介绍学校的基本情况</p>
            <p>描述学校的建校历史、教学文化、荣誉等</p>
            <p>赶快去创建吧~</p>
            <el-button
                type="primary"
                class="create-btn"
                @click="createIntroduce"
            >去创建
            </el-button
            >
        </div>
    </hm-card>
</template>

<script>
import { mapState } from "vuex";
import HmCard from "./Sub/hm-card.vue";
import HmTitle from "./Sub/hm-title.vue";
import HmMore from "./Sub/hm-more.vue";

export default {
    name: "HomeIntroduce",
    components: {
        HmCard,
        HmTitle,
        HmMore,
    },
    data() {
        return {
            loading: true,
            schoolIntroduce: "",
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    created() {
        this.getIntroduce();
    },
    methods: {
        getIntroduce() {
            this._fet("/school/common/introduce?schoolId=" + this.schoolId)
                .then((res) => {
                    if (
                        res.data.code === "200" &&
                        res.data.data[0] &&
                        res.data.data[0].schoolIntroduce
                    ) {
                        this.schoolIntroduce = res.data.data[0].schoolIntroduce
                            .replace(/<[^>]+>/g, "")
                            .replace(/&nbsp;/gi, "");
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createIntroduce() {
            this.$router.push({
                name: "SummaryIntroduce",
            });
        },
    },
};
</script>

<style scoped lang="scss">
.introduce-wrap {
    cursor: pointer;
    position: relative;
    margin-top: 11px;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    background: url(~@/assets/images/home/introduce-bg.png);
    background-size: 100%;

    .introduce-content {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 5px 19px 12px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        font-size: 14px;
        text-indent: 2em;
        line-height: 21px;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.introduce-empty {
    text-align: center;
    color: #666666;
    font-size: 14px;
    line-height: 27px;
    margin-top: 44px;

    .create-btn {
        width: 137px;
        height: 33px;
        background: #2166ee;
        border-radius: 6px;
        border: 0;
        margin-top: 15px;
    }
}
</style>
