// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/home/introduce-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".introduce-wrap[data-v-e49961be]{cursor:pointer;position:relative;margin-top:11px;height:100%;border-radius:6px;overflow:hidden;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100%}.introduce-wrap .introduce-content[data-v-e49961be]{width:100%;position:absolute;bottom:0;padding:5px 19px 12px;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;background:rgba(0,0,0,.5);color:#fff;font-size:14px;text-indent:2em;line-height:21px}.introduce-wrap .introduce-content span[data-v-e49961be]{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.introduce-empty[data-v-e49961be]{text-align:center;color:#666;font-size:14px;line-height:27px;margin-top:44px}.introduce-empty .create-btn[data-v-e49961be]{width:137px;height:33px;background:#2166ee;border-radius:6px;border:0;margin-top:15px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
