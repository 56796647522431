<!-- 首页-校园公告 -->
<template>
    <hm-card
        v-loading="loading"
        :height="276"
        class="home-notice"
    >
        <hm-title title="公告通知">
            <div class="hm-title__noRead" @click="goNoticeList">
                <div class="hm-title__no" >未读</div>
                <div class="hm-title__noNum">{{ statusNumber}}</div>
            </div>
        </hm-title>
        <hm-more @click="releaseNotice"></hm-more>
        <div
            v-if="list.length > 0"
            class="notice-wrap"
        >
            <!-- 左侧单独显示 -->
            <div class="notice-left" @click="showDetail(list[indexIcon])">
                <img
                    :src="list[indexIcon].noticeImg"
                    alt=""
                    class="notice-left__img"
                />
                <div class="notice-left__title ellipsis">
                    {{ list[indexIcon].title }}
                </div>
            </div>
            <!-- 右侧列表显示 -->
            <div v-if="list.length > 1" class="notice-list">
                <div
                    v-for="(item, index) in list.slice(0, 5)"
                    :key="index"
                    class="notice-box"
                    @mouseenter="mouseEnter(item,index)"
                >
                    <!-- <img
                        :src="item.noticeImg"
                        alt=""
                        class="notice-box-img"
                        @click="showPreview(item.noticeImg)"
                    /> -->
                    <div class="notice-box-right" @click="showDetail(item)">
                        <!-- 标题 -->
                        <div class="notice-box-title ellipsis">
                            {{ item.title }}
                        </div>
                        <!-- 状态 -->
                        <div class="notice-box-status" :class="{'notice-box-statusActive':item.isRead=='0'}">
                            {{ item.isRead=='0'?'未读':'已读' ||'未读' }}
                        </div>
                        <!-- 时间 -->
                        <div class="notice-box-time">
                            {{ item.createTime.slice(0,10) || "" }}
                        </div>
                        <!-- 内容 -->
                        <!-- <div class="notice-box-content ellipsis">
                            {{
                                item.content
                                    .replace(/<[^>]+>/g, "")
                                    .replace(/nbsp;/g, "")
                            }}
                        </div> -->
                        <!-- <div class="notice-box-bottom"> -->
                            <!-- 阅读数 -->
                            <!-- <div class="numberOfReadings"> -->
                                <!-- <img
                                    src="@/assets/images/home/eye.png"
                                    alt=""
                                />
                                <span>{{ item.numberOfReadings || 0 }}</span>
                                <span>人</span>
                            </div> -->
                            <!-- <div class="bottom-right"> -->
                                <!-- 创建人 -->
                                <!-- <div class="createName ellipsis">
                                    {{ item.createName || "" }}
                                </div> -->
                                <!-- 创建时间 -->
                                <!-- <div class="createTime">
                                    {{ item.createTime || "" }}
                                </div> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 没数据 -->
        <div
            v-else-if="!loading"
            class="notice-empty"
        >
            <p>公告通知栏目是向全体人员发布各类信息的窗口</p>
            <p>一般可发布周知性信息、发布性信息和转发性信息等</p>
            <p>赶快去发布吧~</p>
            <el-button
                type="primary"
                class="release-btn"
                @click="releaseNotice"
            >去发布
            </el-button>
        </div>
        <!-- 预览图片 -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="closePreview"
            :url-list="[previewUrl]"
            style="z-index: 9999"
        />
        <!-- 详情 -->
        <dialog-wrapper :dialogObj="detailObj" @handleClose="closeDetail">
            <div class="detail-wrap">
                <div class="detail-title">
                    <span v-if="detailData.isTop === 1" class="topping">置顶</span>
                    <span class="title">{{ detailData.title }}</span>
                </div>
                <div class="detail-sub-title">
                    <span v-if="detailData.numberOfReadings">
                        阅读次数：{{ detailData.numberOfReadings}}
                    </span>
                    <span v-if="detailData.releaseStatus">
                        状态：{{ detailData.releaseStatus | filterReleaseStatus }}
                    </span>
                    <span v-if="detailData.createName" class="ellipsis">
                        创建人：{{ detailData.createName }}
                    </span>
                    <span v-if="detailData.createTime">
                        发布时间：{{ detailData.createTime}}
                    </span>
                </div>
                <div v-html="detailData.content" ref="content" class="detail-content wangeditor"></div>
                <div class="detail-time">
                    <p>生效时间：{{ detailData.effecTime || "" }}</p>
                    <p>失效时间：{{ detailData.invalidTime || "永久生效" }}</p>
                </div>
            </div>
        </dialog-wrapper>
    </hm-card>
</template>

<script>
import { mapState } from "vuex";
import { DialogWrapper } from "common-local";
import HmCard from "./Sub/hm-card.vue";
import HmTitle from "./Sub/hm-title.vue";
import HmMore from "./Sub/hm-more.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import TextTooltip from "@/components/scrollWrapper/SManagerCheckTeacher/Sub/textTooltip.vue";

export default {
    name: "HomeNotice",
    components: {
        TextTooltip,
        HmMore,
        HmTitle,
        HmCard,
        ElImageViewer,
        DialogWrapper,
    },
    data() {
        return {
            loading: true,
            list: [],
            showViewer: false,
            previewUrl: "",
            detailObj: {
                title: "详情",
                dialogVisible: false,
                width: "750px",
            },
            detailData: {},
            noticeImg:'',
            noticeTitle:'',
            statusNumber:'',
            indexIcon:0,        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    filters: {
        filterReleaseStatus(data) {
            switch (data) {
                case "1":
                    return "草稿";
                case "2":
                    return "已发布";
                case "3":
                    return "撤回";
                case "4":
                    return "失效";
                default:
                    return "";
            }
        },
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            this.getSchoolNoticeList()
            this.getUnReadNum()
        },
        /**
         * @Description: 未读公告数量接口
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-16 13:55:31
         */
         getUnReadNum(){
            this._fet("/operate/operateNotice/unReadNum", {
                schoolId: this.schoolId,
            }).then((res) => {
                    if (res.data.code === "200") {
                        this.statusNumber=res.data.data
                    }
                })
         },
        getSchoolNoticeList(){
            this._fet("/school/schoolNotice/getSchoolNoticeList", {
                schoolId: this.schoolId,
                releaseStatus: "2",
                pageNum: 1,
                pageRow: 10,
                noticeType: 1,
                productId: "cloud_campus",
            })
                .then((res) => {
                    if (res.data.code === "200") {
                        res.data.data.list.forEach((item) => {
                            if (item.createTime) {
                                item.createTime = item.createTime.substring(0, 16);
                            }
                            if (item.effecTime) {
                                item.effecTime = item.effecTime.substring(0, 16);
                            }
                            if (item.invalidTime) {
                                item.invalidTime = item.invalidTime.substring(0, 16);
                            }
                        });
                        this.list = res.data.data.list;
                        this.noticeImg=this.list[0]?.noticeImg;
                        this.noticeTitle=this.list[0]?.title;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        releaseNotice() {
            this.$router.push({
                name: "InterconnectionSchoolNotice",
                query: {
                    sourceType: "home",
                    activeTab: "0",
                },
            });
        },
        showPreview(url) {
            this.previewUrl = url;
            this.showViewer = true;
        },
        closePreview() {
            this.showViewer = false;
        },
        showDetail(data) {
            this.detailObj.dialogVisible = true;
            this.detailData = data;
            this.saveReadInfo(data)
            this.$nextTick(() => {
                this.dialogDetailOpen()
            })
        },
        /**
         * @Description: 保存公告已读信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-16 09:19:20
         */
         saveReadInfo(data){
            this._fet('/operate/operateNotice/saveReadInfo', {
                id: data.id,
            }).then(res=>{
                this.getSchoolNoticeList()
                this.getUnReadNum()
            })
        },
        closeDetail() {
            this.detailObj.dialogVisible = false;
        },
        dialogDetailOpen() {
            let that = this
            let imgArr = this.$refs.content.getElementsByTagName("img")
            for (let i = 0; i < imgArr.length; i++) {
                imgArr[i].index = i
                imgArr[i].onclick = function () {
                    that.showPreview(this.src)
                }
            }
        },
        /**
         * @Description: 鼠标移入 触发
         * @DoWhat:
         * @UseScenarios:2
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-14 11:20:00
         */
        mouseEnter(e,index) {
           this.indexIcon = index
            // 当鼠标移入时执行的代码
            this.noticeImg=e.noticeImg;
            this.noticeTitle=e.title
        },
        /**
         * @Description: 跳转到 公告列表页面 并传递未读参数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-08-16 09:45:32
         */
        goNoticeList(){
            this.$router.push({
                path:'/schoolNotice',
                query: {isRead: '0'}
            })
        },
    },
};
</script>

<style scoped lang="scss">
.home-notice {
    padding: 14px 21px 16px 16px;
    // background: url(~@/assets/images/home/noticeIcon2.png) no-repeat #ffffff left 0px bottom 0px;
    background-image: url(~@/assets/images/home/noticeIcon1.png), url(~@/assets/images/home/noticeIcon3.png), url(~@/assets/images/home/noticeIcon2.png);
    background-position: top 14px left 290px, bottom 16px left 4px, bottom left;
    background-repeat: no-repeat, no-repeat, no-repeat;
    // background-size: 523px 49px;
}

.notice-wrap {
    display: flex;
    margin-top: 11px;

    .notice-left {
        cursor: pointer;
        position: relative;
        flex-shrink: 0;
        width: 388px;
        height: 218px;
        padding: 10px;
        box-sizing: border-box;
        border: 2px solid #2166EE;
        border-radius: 6px;
        overflow: hidden;
        margin:0 24px;

        &__img {
            object-fit: cover;
            width: 100%;
            // height: 218px;
            height: 100%;
            border-radius: 6px;
            vertical-align: top;
        }

        &__title {
            width: 95%;
            position: absolute;
            bottom: 10px;
            // right: 10px;
            box-sizing: border-box;
            padding: 3px 9px 11px 7px;
            font-weight: bold;
            color: #ffffff;
            border-radius: 6px;
            background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.5) 100%
            );
        }
    }

    .notice-list {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .notice-box {
            + .notice-box {
                // margin-top: 6px;
            }
            display: flex;
            height: 36px;
            line-height: 36px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            padding: 4.5px 0;
            background: #F7F7F7;
            border-radius: 3px;
            margin-bottom: 8px;
            box-sizing: border-box;
            &:hover {
                transition: all 0.2s ease;
                background-color: #DDEBFF;
                box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
            }
            .notice-box-img {
                cursor: pointer;
                object-fit: cover;
                flex-shrink: 0;
                width: 100px;
                height: 67px;
                border-radius: 6px;
                margin-right: 13px;
            }

            .notice-box-right {
                cursor: pointer;
                flex: 1;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                flex-direction: row;
                align-items: center;
                .notice-box-title {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                    padding: 2px 0;
                    width: 290px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-right: 29px;
                    &::before{
                        content: "";
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        background: #2166EE;
                        border-radius: 50%;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 4px;
                    }
                }

                .notice-box-status{
                    display: inline-block;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    margin-right: 15px;
                }

                .notice-box-statusActive{
                    color: #2166EE;
                }
                .notice-box-time{
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }
                .notice-box-content {
                    flex: 1;
                    font-size: 12px;
                    font-weight: 400;
                    color: #666666;
                }

                .notice-box-bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #999999;
                    font-size: 12px;

                    .bottom-right {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                    }

                    .numberOfReadings,
                    .createName {
                        line-height: 11px;
                    }

                    .numberOfReadings {
                        display: flex;
                        align-items: center;

                        img {
                            width: 15px;
                            height: 10px;
                            margin-right: 4px;
                        }
                    }

                    .createTime {
                        flex-shrink: 0;
                        margin-left: 10px;
                        line-height: 9px;
                    }
                }
            }
        }
    }
}

.notice-empty {
    text-align: center;
    color: #666666;
    font-size: 14px;
    line-height: 27px;
    margin-top: 54px;

    .release-btn {
        width: 137px;
        height: 33px;
        background: #2166ee;
        border-radius: 6px;
        border: 0;
        margin-top: 15px;
    }
}

.detail-wrap {
    max-height: 660px;
    padding-right: 15px;
    overflow: auto;

    .detail-title {
        display: flex;
        justify-content: center;
        min-width: 483px;
        border-bottom: 1px solid #ECECEC;
        padding-bottom: 6px;

        .title {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
        }

        .topping {
            flex-shrink: 0;
            align-self: flex-start;
            margin-top: 4px;
            padding: 2px 6px;
            background: #367DF6;
            border-radius: 4px;
            color: #FFFFFF;
            font-size: 12px;
            margin-right: 10px;
        }
    }

    .detail-sub-title {
        margin-top: 6px;
        display: flex;
        justify-content: center;
        color: #999999;
        font-size: 12px;

        span {
            white-space: nowrap;
            margin: 0 10px;
        }
    }

    .detail-content {
        margin: 14px 0 28px;
        overflow: auto;

        ::v-deep img {
            width: 100%;
            cursor: pointer;
        }
    }

    .detail-time {
        text-align: right;
        padding-right: 18px;

        p {
            color: #333333;
            line-height: 22px;
        }
    }
}

::v-deep .el-dialog {
    &__body {
        padding: 0 4px 28px 34px;
    }
}
</style>
